// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brochure-form-js": () => import("/opt/build/repo/src/pages/brochure-form.js" /* webpackChunkName: "component---src-pages-brochure-form-js" */),
  "component---src-pages-brochures-js": () => import("/opt/build/repo/src/pages/brochures.js" /* webpackChunkName: "component---src-pages-brochures-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-educators-toolkit-js": () => import("/opt/build/repo/src/pages/educators-toolkit.js" /* webpackChunkName: "component---src-pages-educators-toolkit-js" */),
  "component---src-pages-form-success-js": () => import("/opt/build/repo/src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-distributors-js": () => import("/opt/build/repo/src/pages/international-distributors.js" /* webpackChunkName: "component---src-pages-international-distributors-js" */),
  "component---src-pages-ophthosim-educators-portal-js": () => import("/opt/build/repo/src/pages/ophthosim-educators-portal.js" /* webpackChunkName: "component---src-pages-ophthosim-educators-portal-js" */),
  "component---src-pages-ophthosim-mobile-js": () => import("/opt/build/repo/src/pages/ophthosim-mobile.js" /* webpackChunkName: "component---src-pages-ophthosim-mobile-js" */),
  "component---src-pages-ophthosim-js": () => import("/opt/build/repo/src/pages/ophthosim.js" /* webpackChunkName: "component---src-pages-ophthosim-js" */),
  "component---src-pages-otosim-2-js": () => import("/opt/build/repo/src/pages/otosim-2.js" /* webpackChunkName: "component---src-pages-otosim-2-js" */),
  "component---src-pages-otosim-educators-portal-js": () => import("/opt/build/repo/src/pages/otosim-educators-portal.js" /* webpackChunkName: "component---src-pages-otosim-educators-portal-js" */),
  "component---src-pages-otosim-mobile-js": () => import("/opt/build/repo/src/pages/otosim-mobile.js" /* webpackChunkName: "component---src-pages-otosim-mobile-js" */),
  "component---src-pages-our-clients-js": () => import("/opt/build/repo/src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-pneumatosim-js": () => import("/opt/build/repo/src/pages/pneumatosim.js" /* webpackChunkName: "component---src-pages-pneumatosim-js" */),
  "component---src-pages-publications-js": () => import("/opt/build/repo/src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-purchase-ophthosim-mobile-js": () => import("/opt/build/repo/src/pages/purchase/ophthosim-mobile.js" /* webpackChunkName: "component---src-pages-purchase-ophthosim-mobile-js" */),
  "component---src-pages-purchase-otosim-mobile-js": () => import("/opt/build/repo/src/pages/purchase/otosim-mobile.js" /* webpackChunkName: "component---src-pages-purchase-otosim-mobile-js" */),
  "component---src-pages-purchase-purchase-success-js": () => import("/opt/build/repo/src/pages/purchase/purchase-success.js" /* webpackChunkName: "component---src-pages-purchase-purchase-success-js" */),
  "component---src-pages-request-form-js": () => import("/opt/build/repo/src/pages/request-form.js" /* webpackChunkName: "component---src-pages-request-form-js" */),
  "component---src-pages-support-manuals-js": () => import("/opt/build/repo/src/pages/support-manuals.js" /* webpackChunkName: "component---src-pages-support-manuals-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/build/repo/src/pages/termsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

